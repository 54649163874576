import * as SwitchPrimitive from "@radix-ui/react-switch";
import clsx from "clsx";
import React from "react";

interface SwitchProps {
  value: boolean;
  onChange: (value: boolean) => void;
}

const Switch: React.FC<SwitchProps> = ({ value, onChange }) => {
  return (
    <SwitchPrimitive.Root
      checked={value}
      onCheckedChange={(newValue) => {
        if (typeof newValue === "boolean") {
          onChange(newValue);
        }
      }}
      className={clsx(
        "group relative inline-flex h-[24px] w-[44px] flex-shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out data-[state=checked]:bg-du-black data-[state=unchecked]:bg-gray-200"
      )}
    >
      <SwitchPrimitive.Thumb
        className={clsx(
          "pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0 data-[state=checked]:bg-du-yellow data-[state=unchecked]:bg-white"
        )}
      />
    </SwitchPrimitive.Root>
  );
};

export default Switch;
