import clsx from "clsx";
import Header from "components/Header";
import ChevronLeft from "icons/ChevronLeft";
import ChevronRight from "icons/ChevronRight";
import React, { Fragment, useEffect, useState } from "react";
import { useWindowSize } from "usehooks-ts";

import AvailableForRegistrationField from "./fields/AvailableForRegistrationField";
import CreditHoursField from "./fields/CreditHoursField";
import DaysOfWeekField from "./fields/DaysOfWeekField";
import InstructorField from "./fields/InstructorField";
import SearchCatalogField from "./fields/SearchCatalogField";
import Personalize from "./Personalize";

const LeftNavbar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [checkMobileView, setCheckMobileView] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width < 600) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }

    // Collapse the sidebar if the width is less than 600px
    if (width !== 0 && width < 600 && !checkMobileView) {
      setIsCollapsed(true);
      setCheckMobileView(true);
    }
  }, [width]);

  return (
    <Fragment>
      <div
        className={clsx(
          "z-10 bg-du-light-gray py-2",
          isCollapsed
            ? "w-[40px] min-w-[40px] px-2"
            : "w-[230px] min-w-[230px] px-4",
          !isCollapsed && isMobileView && "absolute h-[100%] shadow-lg"
        )}
      >
        <div
          className={clsx(
            "mb-3 flex border-b border-du-dark-gray",
            isCollapsed ? "justify-center px-0 py-3" : "justify-between p-3"
          )}
        >
          <Header
            className={clsx("h-[32px] !text-2xl", isCollapsed && "hidden")}
          >
            Filter By
          </Header>
          <button
            onClick={() => {
              setIsCollapsed((prev) => !prev);
            }}
            className="text-lg"
            title={isCollapsed ? "Show" : "Hide"}
          >
            {isCollapsed ? (
              <ChevronRight className="h-4 w-4" fillColor="#000000" />
            ) : (
              <ChevronLeft className="h-4 w-4" fillColor="#000000" />
            )}
          </button>
        </div>
        <div className={clsx("space-y-7", isCollapsed && "hidden")}>
          <Personalize />
          <AvailableForRegistrationField />
          <SearchCatalogField />
          <DaysOfWeekField />
          <CreditHoursField />
          <InstructorField />
        </div>
      </div>
      <div
        className={clsx(
          "h-full w-[43.5px]",
          !isCollapsed && isMobileView ? "block" : "hidden"
        )}
      />
    </Fragment>
  );
};

export default LeftNavbar;
