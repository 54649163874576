import CheckboxAlt from "components/CheckboxAlt";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import React, { useContext } from "react";

const AvailableForRegistrationField = () => {
  const {
    state: {
      fields: { schedulePrint },
    },
    dispatch,
  } = useContext(ClassSearchContext);

  return (
    <div className="ml-2 flex items-center gap-2">
      <CheckboxAlt
        value={schedulePrint === "Y"}
        onChange={(newValue) => {
          dispatch({
            type: "UPDATE_SCHEDULE_PRINT",
            payload: {
              schedulePrint: newValue ? "Y" : "N",
            },
          });
        }}
      />
      <p className="text-[12px] font-bold">Only Available for Registration</p>
    </div>
  );
};

export default AvailableForRegistrationField;
