import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import React, { useContext } from "react";

const InstructorField = () => {
  const {
    state: {
      fields: { instructor },
    },
    dispatch,
  } = useContext(ClassSearchContext);

  return (
    <div className="space-y-1">
      <label htmlFor="cs-instructor" className="font-bold">
        Instructor
      </label>
      <input
        type="cs-instructor"
        className="w-full rounded-sm border px-1 text-base"
        value={instructor}
        onChange={(e) => {
          const newValue = e.target.value;
          dispatch({
            type: "UPDATE_INSTRUCTOR",
            payload: {
              instructor: newValue,
            },
          });
        }}
      />
    </div>
  );
};

export default InstructorField;
