import clsx from "clsx";
import { DateTime } from "luxon";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import {
  EField,
  fetchValues,
  IAND,
  IField,
  IFilter,
  IOR,
} from "pages/newClassSearch/classSearchQuery";
import { ClassSearchResponse } from "pages/newClassSearch/types";
import React, { useContext, useEffect, useState } from "react";

import SemesterMenu from "./SemesterMenu";
import StartDateOptions from "./StartDateOptions";

export interface StarDateUI {
  startDate: string;
  endDate: string;
  isSelected: boolean;
}

export interface SemesterUI {
  semesterCode: string;
  semesterName: string;
  startDates: StarDateUI[];
  isSelected: boolean;
  fromDate: string;
  toDate: string;
  semesterStartDate: string;
  semesterEndDate: string;
}

export interface Semester {
  semesterCode: string;
  semesterName: string;
  sessionStartDate: string;
  sessionEndDate: string;
  semesterStartDate: string;
  semesterEndDate: string;
}

const StartDateField = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [semesters, setSemesters] = useState<Semester[]>([]);
  const [semesterUIList, setSemesterUIList] = useState<SemesterUI[]>([]);

  const {
    state: {
      fields: { campusCode, academicCareerCode },
    },
    dispatch,
  } = useContext(ClassSearchContext);

  const fetchSemesters = async () => {
    try {
      const getFieldList = (onlineOnly: boolean) => {
        const fieldList: (IAND | IOR | IField)[] = [];

        let _campusCode = campusCode;
        if (onlineOnly) _campusCode = "ADLPH";
        fieldList.push({
          campusCode: {
            equals: _campusCode,
          },
        });

        fieldList.push({
          academicCareerCode: {
            equals: academicCareerCode,
          },
        });

        fieldList.push({
          sessionStartDate: {
            gte: DateTime.now().toFormat("yyyy-MM-dd"),
          },
        });

        if (onlineOnly) {
          fieldList.push({
            locationCode: {
              equals: "WEB",
            },
          });
        }

        return fieldList;
      };

      let filter: IFilter;

      if (campusCode !== "ADLPH") {
        filter = {
          OR: [
            {
              AND: getFieldList(false),
            },
            {
              AND: getFieldList(true),
            },
          ],
        };
      } else {
        filter = {
          AND: getFieldList(false),
        };
      }

      const classSearchPagination = (await fetchValues({
        filter: filter,
        select: [
          EField.semesterCode,
          EField.semesterName,
          EField.sessionStartDate,
          EField.sessionEndDate,
          EField.semesterStartDate,
          EField.semesterEndDate,
        ],
        orderby: {
          semesterCode: "asc",
        },
      })) as ClassSearchResponse;

      setSemesters(
        classSearchPagination.value.map((item) => ({
          semesterCode: item.semesterCode,
          semesterName: item.semesterName,
          sessionStartDate: item.sessionStartDate,
          sessionEndDate: item.sessionEndDate,
          semesterStartDate: item.semesterStartDate,
          semesterEndDate: item.semesterEndDate,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSemesters();
  }, [campusCode, academicCareerCode]);

  return (
    <div className="inline-block min-h-[90px] rounded-md bg-du-light-gray px-4 py-2">
      <div className="mb-2 flex justify-between">
        <p className="mb-2 text-sm font-bold">Start Date</p>
        <button
          className="cursor-pointer text-sm font-semibold text-[#24559A]"
          onClick={() => {
            dispatch({
              type: "UPDATE_SESSION_START_DATE_LIST",
              payload: {
                sessionStartDateList: [],
              },
            });
            setSemesterUIList((prev) => {
              return prev.map((item) => ({
                ...item,
                startDates: item.startDates.map((item) => ({
                  ...item,
                  isSelected: false,
                })),
              }));
            });
          }}
        >
          Reset
        </button>
      </div>
      <div className="relative">
        <div
          className="rounded bg-white px-[8px] py-[7px]"
          onClick={() => {
            setIsMenuOpen((prev) => !prev);
          }}
        >
          <StartDateOptions
            semesterUIList={semesterUIList}
            setSemesterUIList={setSemesterUIList}
          />
        </div>
        <div className={clsx(!isMenuOpen && "hidden")}>
          <SemesterMenu
            semesters={semesters}
            setIsMenuOpen={setIsMenuOpen}
            semesterUIList={semesterUIList}
            setSemesterUIList={setSemesterUIList}
          />
        </div>
      </div>
    </div>
  );
};

export default StartDateField;
