import clsx from "clsx";
import ErrorModal from "components/ErrorModal";
import Header from "components/Header";
import Highlighted from "components/Highlighted";
import Pagination from "components/Pagination";
import LinesEllipsis from "components/react-ellipsis/LinesEllipsis";
import AngleDown from "icons/AngleDown";
import AngleUp from "icons/AngleUp";
import SquarePlus from "icons/SquarePlus";
import { getPlannerCoursesResponse } from "pages/classSearch/course/resources";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import { ClassUI } from "pages/newClassSearch/types";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import getDuService from "ServiceBroker";
import { UcMyplanCRSPlnr } from "types/degreePlanningDashboardService";

import { CLASS_PAGE_SIZE } from "../MainPage";
import ClassTableSkeleton from "./ClassTableSkeleton";
import InitialResults from "./InitialResults";
import NoResults from "./NoResults";
import SectionList from "./SectionList";

const ClassTable = () => {
  const {
    state: {
      profile: { emplid, impersonate },
      fields: { campusCode, academicCareerCode },
      freeSearch,
    },
    dispatch,
    classSearchState: {
      classResponseUI,
      setClassResponseUI,
      handleSearch,
      classResponseLoading,
      classResponsePristine,
      setClassPagination,
    },
  } = useContext(ClassSearchContext);
  const { PaginationComponent, currentPage, setCurrentPage } = Pagination({
    count: classResponseUI["@odata.count"],
    pageSize: CLASS_PAGE_SIZE,
  });
  const [gotToPage, setGoToPage] = useState(1);

  const [errorModal, setErrorModal] = useState<{
    open: boolean;
    content: ReactNode;
  }>({
    open: false,
    content: <div />,
  });

  const itemsTo = Math.min(
    CLASS_PAGE_SIZE + CLASS_PAGE_SIZE * (currentPage - 1),
    classResponseUI["@odata.count"]
  );
  const itemsFrom = Math.max(
    itemsTo % CLASS_PAGE_SIZE === 0
      ? itemsTo - (CLASS_PAGE_SIZE - 1)
      : itemsTo - (itemsTo % CLASS_PAGE_SIZE),
    1
  );

  const handleToggleClassItem = (classIdx: number) => {
    setClassResponseUI((prev) => ({
      ...prev,
      value: prev.value.map((item, idx) => {
        if (idx === classIdx) {
          return {
            ...item,
            isCollapsed: !item.isCollapsed,
            maxLines: !item.isCollapsed ? 1 : 999,
          };
        }
        return item;
      }),
    }));
  };

  const handleAddClassToPlanner = async (classItem: ClassUI) => {
    setPlannerBtnAsLoading(classItem);
    const payload = {
      UC_MYPLAN_ADCRS_REQ: {
        EMPLID: emplid,
        ACAD_CAREER: academicCareerCode,
        CRSE_ID: "",
        STRM: classItem.semesterCode,
        SUBJECT: classItem.subjectCode,
        CATALOG_NBR: classItem.catalogNumber,
        CAMPUS: campusCode,
        REQUIREMENT: "",
        RQ_LINE_NBR: "",
      },
    };

    try {
      const result = await getDuService(
        {
          service: "classSearchService",
          method: "putAddToPlanner",
        },
        payload
      );

      fetchPlannerList();
    } catch (error) {
      // send event to angular
      window.dispatchEvent(
        new CustomEvent("showAddToPlannerErrorEvent", {
          detail: {
            response: error,
          },
        })
      );
      setErrorModal({
        open: true,
        content: (
          <div>
            <p className="font-bold">Error</p>
            <p className="text-sm">{JSON.stringify((error as any)?.data)}</p>
          </div>
        ),
      });
    }
  };

  const handleRemoveClassFromPlanner = async (classItem: ClassUI) => {
    setPlannerBtnAsLoading(classItem);
    const payload = {
      UC_MYPLN_DELCRS_REQ: {
        UC_MYPLN_CRSE_LIST: [
          {
            SUBJECT: classItem.subjectCode,
            CATALOG_NBR: classItem.catalogNumber,
          },
        ],
        EMPLID: emplid,
        ACAD_CAREER: academicCareerCode,
        STRM: classItem.semesterCode,
      },
    };

    const result = await getDuService(
      {
        service: "classSearchService",
        method: "deleteFromPlanner",
      },
      payload
    );
    fetchPlannerList();
  };

  const setPlannerBtnAsLoading = (classItem: ClassUI) => {
    setClassResponseUI((prev) => {
      const classUIList = prev.value;
      for (let i = 0; i < classUIList.length; i++) {
        const _classItem = classUIList[i];
        if (
          _classItem.subjectCode === classItem.subjectCode &&
          _classItem.catalogNumber === classItem.catalogNumber
        ) {
          classItem.plannerStatus = "loading";
        }
      }
      return {
        ...prev,
        value: classUIList,
      };
    });
  };

  const fetchPlannerList = async () => {
    const career = academicCareerCode;
    const plannerList: UcMyplanCRSPlnr[] | undefined =
      await getPlannerCoursesResponse(career);

    if (plannerList) {
      dispatch({
        type: "UPDATE_PLANNER_LIST",
        payload: {
          plannerList,
        },
      });

      // update planner status
      setClassResponseUI((prev) => {
        const classUIList = prev.value;
        for (let i = 0; i < classUIList.length; i++) {
          const classItem = classUIList[i];
          classItem.plannerStatus = "add";
          const className = `${classItem.subjectCode} ${classItem.catalogNumber}`;
          if (plannerList) {
            for (let i = 0; i < plannerList.length; i++) {
              const plannerItem = plannerList[i];
              if (plannerItem.COURSE === className) {
                classItem.plannerStatus = "remove";
              }
            }
          }
        }
        return {
          ...prev,
          value: classUIList,
        };
      });
    }
  };

  useEffect(() => {
    handleSearch(CLASS_PAGE_SIZE, CLASS_PAGE_SIZE * (currentPage - 1));
  }, [currentPage]);

  useEffect(() => {
    setClassPagination({
      top: CLASS_PAGE_SIZE,
      skip: CLASS_PAGE_SIZE * (currentPage - 1),
    });
  }, [classResponseUI]);

  if (classResponseLoading) {
    return <ClassTableSkeleton />;
  } else if (classResponsePristine) {
    return <InitialResults />;
  } else if (!classResponsePristine && classResponseUI.value.length === 0) {
    return <NoResults />;
  } else {
    return (
      <div>
        <Header className="!text-2xl">
          {itemsFrom} - {itemsTo} of {classResponseUI["@odata.count"]} result
          {classResponseUI["@odata.count"] > 1 && "s"}
        </Header>
        <div className="space-y-3">
          {classResponseUI.value.map((classItem, idx) => (
            <div
              key={idx}
              className={clsx(
                "rounded-md border border-solid border-du-dark-gray p-4",
                classItem.isCollapsed ? "bg-white" : "bg-[#F5F5F5]"
              )}
            >
              <div
                className={clsx(
                  "flex items-center gap-2",
                  classItem.isCollapsed ? "" : "mb-3"
                )}
              >
                <div className="w-[65px]">
                  <div className="box-content inline-flex h-[45px] w-[45px] flex-col items-center rounded-lg border border-solid border-black bg-white px-2 py-2">
                    <p className="inline-block font-bold">
                      <Highlighted
                        text={classItem.subjectCode}
                        highlight={freeSearch}
                      />
                    </p>
                    <p className="inline-block text-du-black">
                      <Highlighted
                        text={classItem.catalogNumber}
                        highlight={freeSearch}
                      />
                    </p>
                  </div>
                </div>
                <div className="!w-[50%] grow">
                  <p className="mb-[-5px]">
                    <Highlighted
                      text={classItem.semesterName}
                      highlight={freeSearch}
                      className="text-[13px] text-du-skyblue"
                    />
                  </p>
                  <p className="font-bold">
                    <Highlighted
                      text={classItem.courseTitle}
                      highlight={freeSearch}
                    />
                  </p>
                  <div>
                    <LinesEllipsis
                      text={classItem.courseDescription}
                      maxLine={classItem.maxLines}
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                      highlight={freeSearch}
                    />
                  </div>
                </div>
                <div className="flex w-[20%] justify-end space-x-2">
                  <button className="rounded-full border border-solid border-black bg-white px-2 py-1 font-bold">
                    {classItem.creditHours}&nbsp;Credits
                  </button>
                  {/* <button className="rounded-full border border-solid border-black bg-white px-2 py-1 font-bold">
                  2&nbsp;Sections
                </button> */}
                </div>
                <div className="flex w-[20%]">
                  {classItem.plannerStatus === "add" && (
                    <button
                      className={clsx(
                        "inline-flex w-[160px] items-center rounded-md border-none bg-black px-3 py-2 text-center font-bold text-white",
                        impersonate && "cursor-not-allowed"
                      )}
                      onClick={() => {
                        handleAddClassToPlanner(classItem);
                      }}
                      disabled={impersonate}
                    >
                      <SquarePlus
                        className="mr-1 w-4 min-w-[16px]"
                        fillColor="#ffffff"
                      />{" "}
                      Add to Planner
                    </button>
                  )}
                  {classItem.plannerStatus === "loading" && (
                    <button
                      className="inline-flex items-center rounded-md border-none bg-du-yellow px-3 py-2 text-center font-bold text-black"
                      onClick={() => {
                        handleAddClassToPlanner(classItem);
                      }}
                    >
                      Loading...
                    </button>
                  )}
                  {classItem.plannerStatus === "remove" && (
                    <button
                      className={clsx(
                        "inline-flex w-[210px] items-center rounded-md border-none bg-[#459235] px-3 py-2 text-center text-[12px] font-bold text-white",
                        impersonate && "cursor-not-allowed"
                      )}
                      onClick={() => {
                        handleRemoveClassFromPlanner(classItem);
                      }}
                      disabled={impersonate}
                    >
                      <SquarePlus
                        className="mr-1 w-4 min-w-[16px]"
                        fillColor="#ffffff"
                      />{" "}
                      Remove from Planner
                    </button>
                  )}
                </div>
                <div>
                  <button
                    onClick={() => {
                      handleToggleClassItem(idx);
                    }}
                    className={clsx("border-none")}
                  >
                    <AngleUp
                      className={clsx("w-5", classItem.isCollapsed && "hidden")}
                      fillColor="#646464"
                    />
                    <AngleDown
                      className={clsx(
                        "w-5",
                        !classItem.isCollapsed && "hidden"
                      )}
                      fillColor="#646464"
                    />
                  </button>
                </div>
              </div>
              <SectionList classItem={classItem} />
            </div>
          ))}
        </div>
        <PaginationComponent />
        <div className="flex items-center gap-2">
          <span className="font-bold">Go to page</span>
          <input
            className="w-14 rounded-md border border-solid border-du-black p-2"
            type="number"
            value={gotToPage}
            min={1}
            max={Math.ceil(classResponseUI["@odata.count"] / CLASS_PAGE_SIZE)}
            onChange={(e) => {
              const newPage = e.target.valueAsNumber;
              setGoToPage(newPage);
            }}
          />
          <button
            className="rounded-md border border-solid border-du-yellow bg-du-yellow px-4 py-2 font-bold"
            onClick={() => {
              setCurrentPage(gotToPage);
            }}
          >
            Go
          </button>
        </div>
        <ErrorModal
          open={errorModal.open}
          content={errorModal.content}
          action={(open) => setErrorModal({ ...errorModal, open })}
        />
      </div>
    );
  }
};

export default ClassTable;
