import React from "react";

const NoResults = () => {
  return (
    <div className="mt-4 flex h-[400px] flex-col items-center justify-center">
      <p className="font-bold">
        It seems we cound't find any classes matching your search criteria.
      </p>
      <p className="font-bold">
        Please try refining your search with different options for available
        classes.
      </p>
    </div>
  );
};

export default NoResults;
