import clsx from "clsx";
import React, { useContext } from "react";

import { ClassSearchContext } from "../classSearchContext";
import { resetAllEventChannel } from "../events/resetAllEventMsg";

const ResetAllButton = () => {
  const {
    classSearchState: { classResponseLoading },
    dispatch,
  } = useContext(ClassSearchContext);

  return (
    <button
      className={clsx(
        "all-revert flex cursor-pointer items-end gap-2 border-none bg-white bg-none text-sm font-normal text-du-blue hover:underline",
        classResponseLoading ? "!cursor-not-allowed" : ""
      )}
      onClick={() => {
        dispatch({
          type: "RESET_ALL",
        });
        resetAllEventChannel.emit("onResetAll");
      }}
    >
      Reset All
    </button>
  );
};

export default ResetAllButton;
