import CheckboxAlt from "components/CheckboxAlt";
import { DateTime } from "luxon";
import React from "react";

import { SemesterUI } from "./StartDateField";

interface StartDateListProps {
  semesterUIList: SemesterUI[];
  setSemesterUIList: React.Dispatch<React.SetStateAction<SemesterUI[]>>;
}

const StartDateList: React.FC<StartDateListProps> = ({
  semesterUIList,
  setSemesterUIList,
}) => {
  const selectedSemesterUIs = semesterUIList.filter((item) => item.isSelected);

  if (selectedSemesterUIs.length === 0) {
    return <ul />;
  }
  return (
    <ul>
      {selectedSemesterUIs[0].startDates.map((item, idx) => (
        <li key={idx} className="!py-2 px-4">
          <div className="flex gap-3 rounded bg-white p-2">
            <div>
              <CheckboxAlt
                id={`checkbox-startdate-${idx}`}
                value={item.isSelected}
                onChange={() => {
                  setSemesterUIList((semesterUIList) => {
                    const newSemesterUIList = semesterUIList.map(
                      (semesterUIItem) => {
                        if (
                          semesterUIItem.semesterCode ===
                          selectedSemesterUIs[0].semesterCode
                        ) {
                          semesterUIItem.startDates =
                            semesterUIItem.startDates.map(
                              (startDate, index) => {
                                if (idx === index) {
                                  startDate.isSelected = !startDate.isSelected;
                                }
                                return startDate;
                              }
                            );
                        }
                        return semesterUIItem;
                      }
                    );
                    return newSemesterUIList;
                  });
                }}
              />
            </div>
            <p className="select-none font-bold">
              {DateTime.fromISO(item.startDate).toFormat("MMMM d")}
            </p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default StartDateList;
