import clsx from "clsx";
import { DateTime } from "luxon";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import React, { useContext, useEffect, useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

import { Semester, SemesterUI, StarDateUI } from "./StartDateField";
import StartDateList from "./StartDateList";

interface SemesterMenuProps {
  semesters: Semester[];
  setIsMenuOpen: (value: boolean) => void;
  semesterUIList: SemesterUI[];
  setSemesterUIList: React.Dispatch<React.SetStateAction<SemesterUI[]>>;
}

const SemesterMenu: React.FC<SemesterMenuProps> = ({
  semesters,
  setIsMenuOpen,
  semesterUIList,
  setSemesterUIList,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const { dispatch } = useContext(ClassSearchContext);

  const getSemesterUIList = (semesters: Semester[]): SemesterUI[] => {
    const semesterUIList: SemesterUI[] = [];
    const semestersUnique = new Set<string>();

    for (let i = 0; i < semesters.length; i++) {
      const semester = semesters[i];
      semestersUnique.add(`${semester.semesterCode}-${semester.semesterName}`);
    }

    const semestersUniqueArr = Array.from(semestersUnique);
    for (let i = 0; i < semestersUniqueArr.length; i++) {
      const semester = semestersUniqueArr[i];
      const [code, name] = semester.split("-");
      const startDates = semesters
        .filter((semester) => semester.semesterCode === code)
        .map((semester) => ({
          startDate: semester.sessionStartDate,
          endDate: semester.sessionEndDate,
          semesterStartDate: semester.semesterStartDate,
          semesterEndDate: semester.semesterEndDate,
          isSelected: false,
        }))
        .sort(
          (a, b) =>
            new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
        );

      let startDatesUnique = startDates.reduce(
        (acc, curr) => ({ ...acc, [curr.startDate]: curr }),
        {}
      );
      startDatesUnique = Object.values(startDatesUnique);

      semesterUIList.push({
        semesterCode: code,
        semesterName: name,
        startDates: startDatesUnique as StarDateUI[],
        isSelected: i === 0,
        fromDate: startDates[0].startDate,
        toDate: startDates[startDates.length - 1].endDate,
        semesterStartDate: startDates[0].semesterStartDate,
        semesterEndDate: startDates[0].semesterEndDate,
      });
    }

    return semesterUIList;
  };

  useEffect(() => {
    setSemesterUIList(getSemesterUIList(semesters));
  }, [semesters]);

  useOnClickOutside(divRef, () => {
    setIsMenuOpen(false);
  });

  return (
    <div ref={divRef} className="relative z-[1]">
      <div className="absolute flex w-[440px] rounded border bg-white shadow-md">
        <div className="w-[55%]">
          <p className="cursor-pointer px-4 py-2 font-bold text-[#5187BF]">
            Terms
          </p>
          <ul>
            {semesterUIList.map((item) => (
              <li
                key={item.semesterCode}
                className={clsx(
                  "cursor-pointer select-none !py-2 px-4",
                  item.isSelected && "bg-[#FEFAEB]"
                )}
              >
                <div
                  onClick={() => {
                    setSemesterUIList((prev) =>
                      prev.map((semesterUI) => ({
                        ...semesterUI,
                        isSelected:
                          semesterUI.semesterCode === item.semesterCode,
                      }))
                    );
                  }}
                >
                  <p className="font-bold">{item.semesterName}</p>
                  <p className="font-semibold">
                    {DateTime.fromISO(item.semesterStartDate).toFormat(
                      "MMMM d"
                    )}
                    {" - "}
                    {DateTime.fromISO(item.semesterEndDate).toFormat("MMMM d")}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-[45%] bg-[#FEFAEB]">
          <p className="px-4 py-2 font-bold text-[#5187BF]">Start Dates</p>
          <div className="flex h-[80%] flex-col justify-between">
            <StartDateList
              semesterUIList={semesterUIList}
              setSemesterUIList={setSemesterUIList}
            />
            <div className="flex items-end justify-center">
              <button
                className="rounded border-none bg-black px-3 py-2 text-sm font-bold text-white"
                onClick={() => {
                  const sessionStartDateList = semesterUIList
                    .flatMap((semesterUI) => semesterUI.startDates)
                    .filter((startDate) => startDate.isSelected)
                    .map((startDate) => startDate.startDate);

                  dispatch({
                    type: "UPDATE_SESSION_START_DATE_LIST",
                    payload: {
                      sessionStartDateList,
                    },
                  });
                  setIsMenuOpen(false);
                }}
              >
                Apply Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SemesterMenu;
