import Switch from "components/Switch";
import GraduationCap from "icons/GraduationCap";
import School from "icons/School";
import React, { Fragment, useContext, useEffect, useState } from "react";

import { ClassSearchContext } from "../classSearchContext";
import { campusDisplay, careerDisplay } from "../display";
import CampusField from "./fields/CampusField";
import CareerField from "./fields/CareerField";

const Personalize = () => {
  const [isPersonalizeEnabled, setIsPersonalizeEnabled] = useState(true);

  const {
    state: {
      profile: { campusCode, academicCareerCode },
    },
    dispatch,
  } = useContext(ClassSearchContext);

  useEffect(() => {
    if (isPersonalizeEnabled) {
      dispatch({
        type: "UPDATE_CAMPUS_CODE",
        payload: {
          campusCode,
        },
      });
      dispatch({
        type: "UPDATE_ACADEMIC_CAREER_CODE",
        payload: {
          academicCareerCode,
        },
      });
    }
  }, [isPersonalizeEnabled]);

  return (
    <div>
      <div className="flex items-center justify-between rounded-sm border bg-[#fafafa] p-2">
        <div>
          <p className="font-bold">Personalize</p>
          <div className="group">
            <span className="cursor-pointer text-xs font-bold text-du-skyblue underline">
              What is this?
            </span>
            <div className="invisible absolute mt-[10px] w-[200px] space-y-2 rounded bg-du-black px-2 py-2 text-sm shadow-lg group-hover:visible group-hover:z-50">
              <p className="text-sm text-white">
                We aim to give you a better user experience. Therefore, search
                has been personalized based in your information.
              </p>
              <div className="space-y-2">
                {campusDisplay[campusCode] && (
                  <p className="flex items-center gap-2">
                    <School className="h-5 w-5" />
                    <span className="text-sm font-bold text-white">
                      {campusDisplay[campusCode]}
                    </span>
                  </p>
                )}
                {careerDisplay[academicCareerCode] && (
                  <p className="flex items-center gap-2">
                    <GraduationCap className="h-5 w-5" />
                    <span className="text-sm font-bold text-white">
                      {careerDisplay[academicCareerCode]}
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Switch
            value={isPersonalizeEnabled}
            onChange={(value) => {
              setIsPersonalizeEnabled(value);
            }}
          />
        </div>
      </div>

      {!isPersonalizeEnabled && (
        <Fragment>
          <CampusField />
          <CareerField />
        </Fragment>
      )}
    </div>
  );
};

export default Personalize;
