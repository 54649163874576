import { createContext, Dispatch } from "react";

import {
  ClassSearchAction,
  classSearchInitialState,
  ClassSearchState,
} from "./classSearchReducer";
import { ClassResponseUI } from "./types";

export const ClassSearchContext = createContext<{
  state: ClassSearchState;
  dispatch: Dispatch<ClassSearchAction>;
  classSearchState: {
    classResponseUI: ClassResponseUI;
    setClassResponseUI: Dispatch<React.SetStateAction<ClassResponseUI>>;
    classFirstLoad: boolean;
    setClassFirstLoad: Dispatch<React.SetStateAction<boolean>>;
    classResponsePristine: boolean;
    classResponseLoading: boolean;
    classPagination: {
      top: number;
      skip: number;
    };
    setClassPagination: Dispatch<
      React.SetStateAction<{
        top: number;
        skip: number;
      }>
    >;
    handleSearch: (top?: number, skip?: number) => Promise<void>;
  };
}>({
  state: classSearchInitialState,
  dispatch: () => {
    //
  },
  classSearchState: {
    classResponseUI: {
      "@odata.context": "",
      "@odata.count": 0,
      "@odata.nextLink": "",
      value: [],
    },
    setClassResponseUI: () => {
      //
    },
    classFirstLoad: true,
    setClassFirstLoad: () => {
      //
    },
    classResponsePristine: true,
    classResponseLoading: false,
    classPagination: {
      top: 10,
      skip: 0,
    },
    setClassPagination: () => {
      //
    },
    handleSearch: async () => {
      //
    },
  },
});
