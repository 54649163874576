export enum EField {
  orgCode = "orgCode",
  courseId = "courseId",
  semesterCode = "semesterCode",
  semesterName = "semesterName",
  semesterStartDate = "semesterStartDate",
  semesterEndDate = "semesterEndDate",
  sessionCode = "sessionCode",
  sessionName = "sessionName",
  sessionStartDate = "sessionStartDate",
  sessionEndDate = "sessionEndDate",
  classSection = "classSection",
  subjectCode = "subjectCode",
  subjectName = "subjectName",
  catalogNumber = "catalogNumber",
  course = "course",
  academicCareerCode = "academicCareerCode",
  academicCareerName = "academicCareerName",
  classNumber = "classNumber",
  component = "component",
  statusCode = "statusCode",
  statusName = "statusName",
  schedulePrint = "schedulePrint",
  enrollmentCapacity = "enrollmentCapacity",
  waitCapacity = "waitCapacity",
  minimumEnrollment = "minimumEnrollment",
  enrollmentTotal = "enrollmentTotal",
  waitTotal = "waitTotal",
  campusCode = "campusCode",
  campusName = "campusName",
  locationCode = "locationCode",
  locationName = "locationName",
  classFormatCode = "classFormatCode",
  classFormatName = "classFormatName",
  classStartDate = "classStartDate",
  classEndDate = "classEndDate",
  cancelDate = "cancelDate",
  classStartTime = "classStartTime",
  classEndTime = "classEndTime",
  classMonday = "classMonday",
  classTuesday = "classTuesday",
  classWednesday = "classWednesday",
  classThursday = "classThursday",
  classFriday = "classFriday",
  classSaturday = "classSaturday",
  classSunday = "classSunday",
  faculty = "faculty",
  facilityId = "facilityId",
  buildingCode = "buildingCode",
  buildingName = "buildingName",
  room = "room",
  classNotesSequence = "classNotesSequence",
  creditHours = "creditHours",
  gradingBasis = "gradingBasis",
  courseTitle = "courseTitle",
  courseDescription = "courseDescription",
}

const FieldDataType: Record<EField, "string" | "boolean" | "number"> = {
  orgCode: "string",
  courseId: "string",
  semesterCode: "string",
  semesterName: "string",
  semesterStartDate: "string",
  semesterEndDate: "string",
  sessionCode: "string",
  sessionName: "string",
  sessionStartDate: "string",
  sessionEndDate: "string",
  classSection: "string",
  subjectCode: "string",
  subjectName: "string",
  catalogNumber: "string",
  course: "string",
  academicCareerCode: "string",
  academicCareerName: "string",
  classNumber: "string",
  component: "string",
  statusCode: "string",
  statusName: "string",
  schedulePrint: "string",
  enrollmentCapacity: "number",
  waitCapacity: "number",
  minimumEnrollment: "number",
  enrollmentTotal: "number",
  waitTotal: "number",
  campusCode: "string",
  campusName: "string",
  locationCode: "string",
  locationName: "string",
  classFormatCode: "string",
  classFormatName: "string",
  classStartDate: "string",
  classEndDate: "string",
  cancelDate: "string",
  classStartTime: "string",
  classEndTime: "string",
  classMonday: "boolean",
  classTuesday: "boolean",
  classWednesday: "boolean",
  classThursday: "boolean",
  classFriday: "boolean",
  classSaturday: "boolean",
  classSunday: "boolean",
  faculty: "string",
  facilityId: "string",
  buildingCode: "string",
  buildingName: "string",
  room: "string",
  classNotesSequence: "string",
  creditHours: "number",
  gradingBasis: "string",
  courseTitle: "string",
  courseDescription: "string",
};

type ExactlyOneKey<K extends keyof any, V, KK extends keyof any = K> = {
  [P in K]: { [Q in P]: V } & { [Q in Exclude<KK, P>]?: never } extends infer O
    ? { [Q in keyof O]: O[Q] }
    : never;
}[K];

interface IOptions {
  equals: string | number | boolean;
  notEquals: string | number | boolean;
  gt: string | number;
  lt: string | number;
  gte: string | number;
  lte: string | number;
  contains: string | number;
}

export type IField = ExactlyOneKey<EField, Partial<IOptions>>;

export interface IAND {
  AND: Array<IField | IAND | IOR>;
}

export interface IOR {
  OR: Array<IField | IAND | IOR>;
}

export type IFilter = IAND | IOR;
type ISelect = EField[];

type IOrderbyOptions = "asc" | "desc";

type IOrderby = Partial<Record<EField, IOrderbyOptions>>;

export interface IQuery {
  select: ISelect;
  filter: IFilter;
  orderby?: IOrderby;
  search?: string; // free text search
  top?: number;
  skip?: number;
}

const fieldParser = (field: IField) => {
  const [fieldName, opeObj] = Object.entries(field)[0];
  const [opeType, opeVal] = Object.entries(opeObj)[0];

  const dataType = FieldDataType[fieldName as EField];
  const opeValParsed = dataType === "string" ? `'${opeVal}'` : opeVal;

  let strOutput = "";
  if (opeType === "equals") {
    strOutput += `${fieldName} eq ${opeValParsed}`;
  } else if (opeType === "notEquals") {
    strOutput += `${fieldName} ne ${opeValParsed}`;
  } else if (opeType === "gt") {
    strOutput += `${fieldName} gt ${opeValParsed}`;
  } else if (opeType === "lt") {
    strOutput += `${fieldName} lt ${opeValParsed}`;
  } else if (opeType === "gte") {
    strOutput += `${fieldName} ge ${opeValParsed}`;
  } else if (opeType === "lte") {
    strOutput += `${fieldName} le ${opeValParsed}`;
  } else if (opeType === "contains") {
    strOutput += `contains(${fieldName}, ${opeValParsed})`;
  }

  return strOutput;
};

const parseFilter = (object: IAND | IOR, filter = "") => {
  const [boolOpe, valueList] = Object.entries(object)[0];
  let boolOpeTxt = "";
  if (boolOpe === "AND") {
    boolOpeTxt = "and";
  } else if (boolOpe === "OR") {
    boolOpeTxt = "or";
  }

  if (valueList.length === 0) return filter;

  if (valueList.length > 1) filter += "(";
  for (let i = 0; i < valueList.length; i++) {
    const value = valueList[i];
    const _key = Object.keys(value)[0];
    if (_key === "AND") {
      filter += parseFilter(value as IAND);
    } else if (_key === "OR") {
      filter += parseFilter(value as IOR);
    } else {
      filter += fieldParser(value as IField);
    }

    if (i !== valueList.length - 1) {
      filter += ` ${boolOpeTxt} `;
    }
  }
  if (valueList.length > 1) filter += ")";
  return filter;
};

const parseSelect = (fields: ISelect) => {
  return fields.join(",");
};

const parseOrderBy = (field: IOrderby) => {
  return Object.entries(field)
    .map(([key, value]) => `${key} ${value}`)
    .join(",");
};

export const fetchValues = async (
  query: IQuery,
  type: "value" | "class" = "value"
) => {
  /**
   * type value gives distinct values (slow)
   * type class gives all values (fast)
   */

  const url = `https://apigateway-nonprod.umgc.edu/qa-expclassroom/${type}`;
  const urlObj = new URL(url);
  urlObj.searchParams.append("$select", parseSelect(query.select));
  urlObj.searchParams.append("$filter", parseFilter(query.filter));

  if (query.search) {
    urlObj.searchParams.append("$search", query.search);
  }

  if (query.orderby) {
    urlObj.searchParams.append("$orderby", parseOrderBy(query.orderby));
  }

  if (query.top !== undefined && query.skip !== undefined) {
    urlObj.searchParams.append("$top", String(query.top));
    urlObj.searchParams.append("$skip", String(query.skip));
  }

  const result = await fetch(urlObj.href);
  const json = await result.json();

  // for semesterName field swap year and term
  json.value = json.value.map((item: any) => {
    if (item.semesterName) {
      const [year, term] = item.semesterName.split(" ");
      item.semesterName = `${term} ${year}`;
    }
    return item;
  });

  // replace "Online via the Web" to "Online"
  json.value = json.value.map((item: any) => {
    if (item?.locationName === "Online via the Web") {
      item.locationName = "Online";
    }
    if (item?.classFormatName === "Online via the Web") {
      item.classFormatName = "Online";
    }
    return item;
  });

  return json;
};
