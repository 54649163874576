import CheckboxAlt from "components/CheckboxAlt";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import React, { useContext } from "react";

const AvailableForRegistrationField = () => {
  const {
    state: {
      fields: { schedulePrint },
    },
    dispatch,
  } = useContext(ClassSearchContext);

  return (
    <div>
      <div className="flex items-center gap-2">
        <CheckboxAlt
          value={schedulePrint === "Y"}
          onChange={(newValue) => {
            dispatch({
              type: "UPDATE_SCHEDULE_PRINT",
              payload: {
                schedulePrint: newValue ? "Y" : "N",
              },
            });
          }}
        />
        <p className="text-[12px] font-bold">Only Available for Registration</p>
      </div>
    </div>
  );
};

export default AvailableForRegistrationField;
