import HighlightedOption from "components/HighlightedOption";
import React, { useContext, useEffect, useState } from "react";
import ReactSelect from "react-select";

import { ClassSearchContext } from "../../classSearchContext";
import { EField, fetchValues, IAND, IField, IOR } from "../../classSearchQuery";
import { ClassSearchItem, ClassSearchResponse } from "../../types";

interface LocationItem {
  locationCode: string;
  locationName: string;
}

const LocationField = () => {
  const [locations, setLocations] = useState<LocationItem[]>([]);
  const [selectCode, setSelectCode] = useState("");

  const {
    state: {
      fields: { campusCode, academicCareerCode, sessionStartDateList },
    },
    dispatch,
  } = useContext(ClassSearchContext);

  const fetchLocations = async () => {
    try {
      const conditionList: (IAND | IOR | IField)[] = [
        {
          campusCode: {
            equals: campusCode,
          },
        },
        {
          academicCareerCode: {
            equals: academicCareerCode,
          },
        },
      ];

      if (sessionStartDateList.length > 0) {
        conditionList.push({
          OR: sessionStartDateList.map((startDate) => ({
            sessionStartDate: {
              equals: startDate,
            },
          })),
        });
      }

      const classSearchPagination = (await fetchValues({
        filter: {
          AND: conditionList,
        },
        select: [EField.locationCode, EField.locationName],
        orderby: {
          locationName: "asc",
        },
      })) as ClassSearchResponse;

      if (campusCode !== "ADLPH") {
        classSearchPagination.value.push({
          locationCode: "WEB",
          locationName: "Online via the Web",
        } as ClassSearchItem);

        classSearchPagination.value.sort((a, b) => {
          if (a.locationName < b.locationName) {
            return -1;
          }
          if (a.locationName > b.locationName) {
            return 1;
          }
          return 0;
        });
      }

      setLocations(
        classSearchPagination.value.map((item) => ({
          locationCode: item.locationCode,
          locationName:
            item.locationName === "Online via the Web"
              ? "Online"
              : item.locationName,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, [campusCode, academicCareerCode, sessionStartDateList]);

  return (
    // <div className="inline-block min-h-[90px] min-w-[450px] max-w-[450px] rounded-md bg-du-light-gray px-4 py-2">
    <div className="inline-block min-h-[90px]  rounded-md bg-du-light-gray px-4 py-2">
      <div className="mb-2 flex justify-between">
        <p className="text-sm font-bold">Location</p>
        <button
          className="cursor-pointer text-sm font-semibold text-[#24559A]"
          onClick={() => {
            setSelectCode(String(new Date().getTime()));
            dispatch({
              type: "UPDATE_LOCATION_CODE_LIST",
              payload: {
                locationCodeList: [],
              },
            });
          }}
        >
          Reset
        </button>
      </div>
      <div>
        <ReactSelect
          key={`location-multi-select-field-${selectCode}`}
          name="location-multi-select-field"
          isMulti
          options={locations}
          onChange={(values) => {
            const newLocationCodeList = values.map(
              (item) => (item as LocationItem).locationCode
            );
            dispatch({
              type: "UPDATE_LOCATION_CODE_LIST",
              payload: {
                locationCodeList: newLocationCodeList,
              },
            });
          }}
          getOptionLabel={(option) => (option as LocationItem).locationName}
          getOptionValue={(option) => (option as LocationItem).locationCode}
          components={{ DropdownIndicator: null, Option: HighlightedOption }}
        />
      </div>
    </div>
  );
};

export default LocationField;
