import { getPlannerCoursesResponse } from "pages/classSearch/course/resources";
import React, { useEffect, useReducer, useState } from "react";
import { angularize } from "react-in-angularjs";
import getDuService from "ServiceBroker";
import { UcMyplanCRSPlnr } from "types/degreePlanningDashboardService";
import { ProfileStudentResponse } from "types/profileStudentIDService";

import { ClassSearchContext } from "./classSearchContext";
import { EField, fetchValues, IQuery } from "./classSearchQuery";
import {
  classSearchInitialState,
  classSearchReducer,
  getFieldList,
  getFilter,
} from "./classSearchReducer";
import LeftNavbar from "./leftNavbar/LeftNavbar";
import MainPage from "./mainPage/MainPage";
import { ClassResponse, ClassResponseUI } from "./types";

const NewClassSearch = () => {
  const [state, dispatch] = useReducer(
    classSearchReducer,
    classSearchInitialState
  );
  const [classResponseUI, setClassResponseUI] = useState<ClassResponseUI>({
    "@odata.context": "",
    "@odata.count": 0,
    "@odata.nextLink": "",
    value: [],
  });
  // TODO: maybe add these states to query reducer?
  const [classResponsePristine, setClassResponsePristine] = useState(true);
  const [classFirstLoad, setClassFirstLoad] = useState(false);
  const [classResponseLoading, setClassResponseLoading] = useState(false);
  const [classPagination, setClassPagination] = useState<{
    top: number;
    skip: number;
  }>({
    top: 10,
    skip: 0,
  });

  const [plannerListMemo, setPlannerListMemo] = useState<
    Record<string, UcMyplanCRSPlnr[] | undefined>
  >({});

  const handleSearch = async (top?: number, skip?: number) => {
    const fieldList = getFieldList(state.fields);

    if (!classFirstLoad) return;

    const filter = getFilter(state.fields, fieldList);

    const query: IQuery = {
      select: [
        EField.campusCode,
        EField.campusName,
        EField.semesterCode,
        EField.semesterName,
        EField.academicCareerCode,
        EField.subjectCode,
        EField.catalogNumber,
        EField.courseTitle,
        EField.courseDescription,
        EField.creditHours,
      ],
      filter,
      orderby: {
        subjectCode: "asc",
        catalogNumber: "asc",
      },
    };

    if (top !== undefined && skip !== undefined) {
      query.top = top;
      query.skip = skip;
    }

    if (state.freeSearch) {
      query.search = state.freeSearch;
    }

    setClassResponseLoading(true);
    const classResponse = (await fetchValues(query)) as ClassResponse;
    setClassResponseLoading(false);
    if (!classResponse?.value) return;

    const classResponseUI = {
      ...classResponse,
      value: classResponse.value.map((item) => ({
        ...item,
        isCollapsed: true,
        maxLines: 1,
        plannerStatus: "add",
      })),
    } as ClassResponseUI;

    setClassResponseUI(classResponseUI);
    setClassResponsePristine(false);
    await fetchPlannerList();
  };

  const fetchPlannerList = async () => {
    const career = state.fields.academicCareerCode;
    let plannerList: UcMyplanCRSPlnr[] | undefined;

    if (plannerListMemo[career]) {
      plannerList = plannerListMemo[career];
    } else {
      plannerList = await getPlannerCoursesResponse(career);
    }

    if (plannerList) {
      setPlannerListMemo((prev) => {
        return {
          ...prev,
          [career]: plannerList,
        };
      });

      dispatch({
        type: "UPDATE_PLANNER_LIST",
        payload: {
          plannerList,
        },
      });

      // update planner status
      setClassResponseUI((prev) => {
        const classUIList = prev.value;
        for (let i = 0; i < classUIList.length; i++) {
          const classItem = classUIList[i];
          classItem.plannerStatus = "add";
          const className = `${classItem.subjectCode} ${classItem.catalogNumber}`;
          if (plannerList) {
            for (let i = 0; i < plannerList.length; i++) {
              const plannerItem = plannerList[i];
              if (plannerItem.COURSE === className) {
                classItem.plannerStatus = "remove";
              }
            }
          }
        }
        return {
          ...prev,
          value: classUIList,
        };
      });
    }
  };

  const fetchStudentProfile = async () => {
    try {
      const profileStudentResponse = (await getDuService({
        service: "profileStudentIDService",
        method: "getProfileStudentID",
      })) as ProfileStudentResponse;

      const emplid = profileStudentResponse.Student_ID.EMPLID;
      const campusCode = profileStudentResponse.Student_ID.CAMPUS;
      const academicCareerCode = profileStudentResponse.Student_ID.CAREER;

      dispatch({
        type: "UPDATE_CAMPUS_CODE",
        payload: {
          campusCode,
        },
      });
      dispatch({
        type: "UPDATE_ACADEMIC_CAREER_CODE",
        payload: {
          academicCareerCode,
        },
      });
      dispatch({
        type: "UPDATE_STUDENT_PROFILE",
        payload: {
          emplid,
          campusCode,
          academicCareerCode,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStudentProfile();
  }, []);

  return (
    <ClassSearchContext.Provider
      value={{
        state,
        dispatch,
        classSearchState: {
          classResponseUI,
          setClassResponseUI,
          classFirstLoad,
          setClassFirstLoad,
          classResponsePristine,
          classResponseLoading,
          classPagination,
          setClassPagination,
          handleSearch,
        },
      }}
    >
      <div>
        {state && (
          <div className="relative flex">
            <LeftNavbar />
            <MainPage />
          </div>
        )}
      </div>
    </ClassSearchContext.Provider>
  );
};

angularize(NewClassSearch, "newClassSearchReact", angular.module("duApp"), {});

export default NewClassSearch;
