import clsx from "clsx";
import Highlighted from "components/Highlighted";
import Cart from "icons/Cart";
import TriangleExclamation from "icons/TriangleExclamation";
import { DateTime } from "luxon";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import {
  ClassUI,
  SectionItem as SectionItemType,
  SectionResponse,
  SectionUI,
} from "pages/newClassSearch/types";
import React, { useContext, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

import Weekdays from "./Weekdays";

interface SectionItemProps {
  sectionItem: SectionUI;
  freeSearch: string;
  handleAddSectionToCart: (
    classItem: ClassUI,
    sectionItem: SectionItemType
  ) => Promise<void>;
  handleRemoveSectionFromCart: (sectionItem: SectionUI) => Promise<void>;
  fetchCartList: (sectionResponse: SectionResponse) => Promise<void>;
  sectionResponse: SectionResponse;
  classItem: ClassUI;
}

const SectionItem: React.FC<SectionItemProps> = ({
  sectionItem,
  freeSearch,
  handleAddSectionToCart,
  handleRemoveSectionFromCart,
  fetchCartList,
  sectionResponse,
  classItem,
}) => {
  const {
    state: {
      profile: { activeTermCodes, impersonate },
    },
  } = useContext(ClassSearchContext);

  const isTermActive = activeTermCodes.some(
    (termCode) => Number(termCode) === Number(sectionItem.semesterCode)
  );

  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    setShowMenu(false);
  });

  return (
    <li>
      <div className="flex flex-col rounded-md border border-solid border-du-dark-gray bg-white p-4 lg:flex-row lg:items-center lg:justify-between">
        <div className="w-auto lg:w-[calc(100%-200px)]">
          <div className="mx-auto flex justify-center gap-4 px-2 py-1 lg:mx-0 lg:inline-flex lg:w-[16.6%] lg:flex-col lg:gap-0 lg:p-0">
            <p className="font-bold lg:w-auto">Class No.</p>
            <p className="overflow-hidden text-ellipsis font-semibold">
              <Highlighted
                text={sectionItem.classNumber}
                highlight={freeSearch}
              />
            </p>
          </div>
          <div className="mx-auto flex justify-center gap-4 px-2 py-1 lg:mx-0 lg:inline-flex lg:w-[16.6%] lg:flex-col lg:gap-0 lg:p-0">
            <p className="font-bold lg:w-auto">Location</p>
            <p className="overflow-hidden text-ellipsis font-semibold">
              <Highlighted
                text={sectionItem.locationName}
                highlight={freeSearch}
              />
            </p>
          </div>
          <div className="mx-auto flex justify-center gap-4 px-2 py-1 lg:mx-0 lg:inline-flex lg:w-[16.6%] lg:flex-col lg:gap-0 lg:p-0">
            <p className="font-bold lg:w-auto">Format</p>
            <p className="overflow-hidden text-ellipsis font-semibold">
              <Highlighted
                text={sectionItem.classFormatName}
                highlight={freeSearch}
              />
            </p>
          </div>
          <div className="mx-auto flex justify-center gap-4 px-2 py-1 lg:mx-0 lg:inline-flex lg:w-[16.6%] lg:flex-col lg:gap-0 lg:p-0">
            <p className="font-bold lg:w-auto">Instructor</p>
            <p className="overflow-hidden text-ellipsis font-semibold">
              <Highlighted text={sectionItem.faculty} highlight={freeSearch} />
            </p>
          </div>
          <div className="mx-auto flex justify-center gap-4 px-2 py-1 lg:mx-0 lg:inline-flex lg:w-[16.6%] lg:flex-col lg:gap-0 lg:p-0">
            <p className="font-bold lg:w-auto">Days & Times</p>
            <p className="overflow-hidden text-ellipsis font-semibold">
              <Weekdays
                monday={sectionItem.classMonday}
                tuesday={sectionItem.classTuesday}
                wednesday={sectionItem.classWednesday}
                thursday={sectionItem.classThursday}
                friday={sectionItem.classFriday}
                saturday={sectionItem.classSaturday}
                sunday={sectionItem.classSunday}
              />
            </p>
            <p className="overflow-hidden text-ellipsis font-semibold">
              <Highlighted
                text={`${
                  sectionItem.classStartTime !== ""
                    ? DateTime.fromFormat(
                        sectionItem.classStartTime,
                        "HH:mm"
                      ).toFormat("h:mm")
                    : ""
                } - ${
                  sectionItem.classEndTime !== ""
                    ? DateTime.fromFormat(
                        sectionItem.classEndTime,
                        "HH:mm"
                      ).toFormat("h:mm")
                    : ""
                }`}
                highlight={freeSearch}
              />
            </p>
          </div>
          <div className="mx-auto flex justify-center gap-4 px-2 py-1 lg:mx-0 lg:inline-flex lg:w-[16.6%] lg:flex-col lg:gap-0 lg:p-0">
            <p className="font-bold lg:w-auto">Dates</p>
            <p className="overflow-hidden text-ellipsis font-semibold">
              {DateTime.fromISO(sectionItem.classStartDate + ".000").toFormat(
                "MMM dd, yyyy"
              )}
              <br />
              {DateTime.fromISO(sectionItem.classEndDate).toFormat(
                "MMM dd, yyyy"
              )}
            </p>
          </div>
        </div>
        <div className="relative flex">
          <div className="inline-flex flex-col items-center gap-1 lg:w-[200px] lg:justify-end">
            <div className="mx-auto rounded-md border border-solid border-[#3A6536] bg-[#D4F2D5] px-2 py-1 text-sm font-bold text-[#3A6536]">
              <div className="flex items-center gap-2">
                <span className="inline-flex h-[22px] w-[22px] items-center justify-center rounded-full bg-[#3A6536] text-sm text-white">
                  {sectionItem.enrollmentCapacity - sectionItem.enrollmentTotal}
                </span>{" "}
                <div className="text-[14px] leading-4">Seats Available</div>
              </div>
            </div>
            <div className="flex items-center">
              {sectionItem.inCart ? (
                <button
                  className="all-revert flex cursor-pointer items-center rounded-md border border-solid border-du-yellow bg-du-yellow px-4 py-1 text-sm font-bold"
                  onClick={async () => {
                    await handleRemoveSectionFromCart(sectionItem);
                    fetchCartList(sectionResponse);
                  }}
                >
                  Remove from Cart
                </button>
              ) : (
                <div>
                  <button
                    disabled={!isTermActive || impersonate}
                    className={clsx(
                      "all-revert mx-auto flex items-center rounded-md border border-solid px-4 py-1 text-sm font-bold",
                      isTermActive || !impersonate
                        ? "cursor-pointer border-du-yellow bg-du-yellow"
                        : "cursor-not-allowed border-du-gray bg-du-gray text-black"
                    )}
                    onClick={async () => {
                      await handleAddSectionToCart(classItem, sectionItem);
                      fetchCartList(sectionResponse);
                    }}
                  >
                    <Cart
                      className="mr-1 w-4 min-w-[16px]"
                      fillColor="#3c3c43"
                    />{" "}
                    Add to Cart
                  </button>
                  {!isTermActive && (
                    <p className="text-xs">
                      <TriangleExclamation
                        fillColor="#dc2626"
                        className="mr-1 inline-block h-[15px] w-[15px]"
                      />
                      You're not eligible to register for the{" "}
                      {sectionItem.semesterName} term. Please contact an
                      advisor.
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          <button
            className="mr-2 w-4 cursor-pointer border-0 after:inline-block after:font-awesome after:text-4xl after:font-light after:text-du-black after:content-['\f142']"
            onClick={() => {
              setShowMenu((prev) => !prev);
            }}
          />
          <div
            ref={ref}
            className={clsx(
              "absolute right-[13px] top-[46px] z-10 w-[200px] space-y-2 rounded-md border border-solid border-black bg-white p-2 shadow-2xl",
              !showMenu && "hidden"
            )}
          >
            <a
              href="#"
              target="_blank"
              className="block rounded-md border border-solid border-black p-2 text-center font-bold"
              rel="noreferrer"
            >
              Explore Syllabus
            </a>
            <button
              className="block w-full rounded-md border border-solid border-black p-2 text-center font-bold"
              onClick={() => {
                const helpWindow = window.open(
                  `https://webapps.umgc.edu/UgcmBook/BPage.cfm?C=${classItem.subjectCode} ${classItem.catalogNumber}&S=${sectionItem.classSection}&Sem=${classItem.semesterCode}`,
                  "helpWin",
                  "toolbar=yes,location=no,scrollbars=yes,resizable=yes,width=800,height=750"
                );
                helpWindow?.focus();
              }}
            >
              Course Materials
            </button>
          </div>
        </div>
      </div>
    </li>
  );
};

export default SectionItem;
