export const campusDisplay: Record<string, string> = {
  ADLPH: "UMGC Stateside",
  EUROP: "UMGC Europe",
  ASIA: "UMGC ASIA",
};

export const careerDisplay: Record<string, string> = {
  UGRD: "Undergraduate",
  GRAD: "Graduate",
  GSAL: "GSAL",
  GR4T: "GR4T",
  USAL: "USAL",
};
