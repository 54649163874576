import Check from "icons/Check";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import { Weekday } from "pages/newClassSearch/types";
import React, { useContext } from "react";

const weekdays: Weekday[] = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const DaysOfWeekField = () => {
  const {
    state: {
      fields: {
        classMonday,
        classTuesday,
        classWednesday,
        classThursday,
        classFriday,
        classSaturday,
        classSunday,
      },
    },
    dispatch,
  } = useContext(ClassSearchContext);

  const getWeekdayValue = (weekday: Weekday) => {
    if (weekday === "monday") {
      return classMonday;
    } else if (weekday === "tuesday") {
      return classTuesday;
    } else if (weekday === "wednesday") {
      return classWednesday;
    } else if (weekday === "thursday") {
      return classThursday;
    } else if (weekday === "friday") {
      return classFriday;
    } else if (weekday === "saturday") {
      return classSaturday;
    } else if (weekday === "sunday") {
      return classSunday;
    }
  };

  const handleClick = (weekday: Weekday) => {
    const newValue = !getWeekdayValue(weekday);
    dispatch({
      type: "UPDATE_DAY_OF_WEEK",
      payload: {
        dayOfWeek: weekday,
        value: newValue,
      },
    });
  };

  return (
    <div className="space-y-1">
      <label htmlFor="cs-instructor" className="font-bold">
        Days of the Week
      </label>
      <div className="space-y-1">
        {weekdays.map((weekday) => (
          <button
            key={weekday}
            className="flex w-full items-center justify-between rounded-md border bg-white p-2 text-left font-semibold capitalize"
            onClick={() => {
              handleClick(weekday);
            }}
          >
            <span className="text-[14px]">{weekday}</span>
            <span>
              {getWeekdayValue(weekday) && (
                <Check className="h-[15px] w-[15px]" fillColor="#3C3C43" />
              )}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default DaysOfWeekField;
