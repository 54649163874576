import CheckboxAlt from "components/CheckboxAlt";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import {
  EField,
  fetchValues,
  IQuery,
} from "pages/newClassSearch/classSearchQuery";
import { careerDisplay, careerOrder } from "pages/newClassSearch/display";
import { ClassSearchResponse } from "pages/newClassSearch/types";
import React, { useContext, useEffect, useState } from "react";

const CareerField = () => {
  const {
    state: {
      fields: { campusCode, academicCareerCode },
    },
    dispatch,
  } = useContext(ClassSearchContext);

  const [careerList, setCareerList] = useState<string[]>([]);

  const fetchCareers = async () => {
    const query: IQuery = {
      select: [EField.academicCareerCode],
      filter: {
        AND: [
          {
            campusCode: {
              equals: campusCode,
            },
          },
        ],
      },
      orderby: {
        academicCareerCode: "asc",
      },
    };

    const classSearchPagination = (await fetchValues(
      query
    )) as ClassSearchResponse;
    const careers = classSearchPagination.value.map(
      (value) => value.academicCareerCode
    );
    setCareerList(careers);
  };

  useEffect(() => {
    fetchCareers();
  }, [campusCode]);

  return (
    <div className="ml-2 mt-5">
      <p className="mb-2 font-bold">Career</p>
      <ul className="space-y-4">
        {careerList
          .sort((a, b) => careerOrder[a] - careerOrder[b])
          .map((career, index) => (
            <li key={index}>
              <div className="flex gap-3">
                <div>
                  <CheckboxAlt
                    value={career === academicCareerCode}
                    onChange={() => {
                      dispatch({
                        type: "UPDATE_ACADEMIC_CAREER_CODE",
                        payload: {
                          academicCareerCode: career,
                        },
                      });
                    }}
                  />
                </div>
                <p className="text-sm font-bold">{careerDisplay[career]}</p>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default CareerField;
