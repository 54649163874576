import clsx from "clsx";
import { AlertType } from "components/alert/Alert";
import Checkbox from "components/Checkbox";
import Paragraph from "components/Paragraph";
import Pen from "icons/Pen";
import TrashAlt from "icons/TrashAlt";
import ConfirmGrading from "pages/shared/editGrading/ConfirmGrading";
import EditGrading from "pages/shared/editGrading/EditGrading";
import React, { useEffect, useRef, useState } from "react";
import getDuService from "ServiceBroker";
import { StudentID } from "types/classSearchService";
import { GradingEnum, GradingModalState } from "types/editGradingModal";

import RemoveClassModal from "./RemoveClassModal";
import { CartItemUI, TermUI } from "./ShoppingCart";

interface CartListProps {
  impersonate?: "true" | "false";
  idCard?: StudentID;
  term: TermUI;
  cartItems: CartItemUI[];
  setCartItems: React.Dispatch<React.SetStateAction<CartItemUI[]>>;
  fetchShoppingCartDetails: (strm: string, career: string) => Promise<void>;
  setAlert: React.Dispatch<React.SetStateAction<AlertType>>;
}

const CartList: React.FC<CartListProps> = ({
  impersonate,
  idCard,
  term,
  cartItems,
  setCartItems,
  fetchShoppingCartDetails,
  setAlert,
}) => {
  const [currCartItem, setCurrCartItem] = useState<CartItemUI | undefined>(
    undefined
  );
  const [gradingModal, setGradingModal] = useState<GradingModalState>({
    currGrading: GradingEnum.Graded,
    newGrading: GradingEnum.Graded,
    isEditModalOpen: false,
    isConfirmModalOpen: false,
  });

  const editBtnRef = useRef<HTMLButtonElement>(null);

  const handleSaveClassToCart = async (
    cartItem: CartItemUI,
    grading: GradingEnum
  ) => {
    if (impersonate === "true") return;
    if (!idCard) return;
    const payload = {
      ShoppingCartReqst: {
        Emplid: idCard.EMPLID,
        Institution: term.INSTITUTION,
        AcadCareer: term.ACAD_CAREER,
        Term: term.STRM,
        ClassNumber: cartItem.ClassNbr,
        GradingBasis: grading,
      },
    };

    try {
      const result = await getDuService(
        {
          service: "classSearchService",
          method: "putShoppingCart",
        },
        payload
      );
      setCartItems((prev) =>
        prev.map((item) => {
          if (item.ClassNbr === cartItem.ClassNbr) {
            item.GradingBasis = cartItem.GradingBasis;
          }
          return item;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveCartItem = async (
    cartItem: CartItemUI,
    strm: string,
    career: string
  ) => {
    if (impersonate === "true") return;
    const deleteUrl = `${strm}/${career}?classNumber=${cartItem.ClassNbr}`;
    try {
      const result = await getDuService(
        {
          service: "classSearchService",
          method: "deleteShoppingCart",
        },
        deleteUrl
      );
      // send event to angular
      window.dispatchEvent(new CustomEvent("updateCartCountEvent"));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!gradingModal.isConfirmModalOpen) {
      if (!editBtnRef.current) return;
      editBtnRef.current.focus();
    }
  }, [gradingModal]);

  return (
    <div>
      {cartItems.length > 0 && (
        <div>
          {cartItems.map((item) => (
            <div key={item.ClassNbr} className="!p-4 even:bg-du-light-gray">
              <div className="mb-4 flex justify-between">
                <div className="flex items-center gap-2">
                  <Checkbox
                    ariaLabelledby={String(item.ClassNbr)}
                    value={item.registerClass}
                    onChange={() => {
                      setCartItems((prev) =>
                        prev.map((cartItem) => {
                          if (cartItem.ClassNbr === item.ClassNbr) {
                            cartItem.registerClass = !cartItem.registerClass;
                          }
                          return cartItem;
                        })
                      );
                    }}
                  />
                  <Paragraph id={String(item.ClassNbr)}>
                    {item.ClassName} - {item.Units} Credits
                  </Paragraph>
                </div>
                <div className="all-revert flex items-center gap-4">
                  <button
                    disabled={impersonate === "true"}
                    ref={editBtnRef}
                    aria-label={`Edit ${item.ClassName} - ${item.Units} Credits`}
                    aria-haspopup="dialog"
                    className="border-none"
                    onClick={() => {
                      setCurrCartItem(item);
                      setGradingModal((prev) => ({
                        ...prev,
                        isEditModalOpen: true,
                        currGrading: item.GradingBasis as GradingEnum,
                        newGrading: item.GradingBasis as GradingEnum,
                      }));
                    }}
                  >
                    <Pen fillColor="#003E6A" className="w-[14px]" />
                  </button>
                  <RemoveClassModal
                    trigger={
                      <button
                        disabled={impersonate === "true"}
                        aria-label={`Remove ${item.ClassName} - ${item.Units} Credits`}
                        className="border-none"
                      >
                        <TrashAlt fillColor="#003E6A" className="w-[14px]" />
                      </button>
                    }
                    action={async () => {
                      if (term.STRM && term.ACAD_CAREER) {
                        await handleRemoveCartItem(
                          item,
                          term.STRM,
                          term.ACAD_CAREER
                        );
                        await fetchShoppingCartDetails(
                          term.STRM,
                          term.ACAD_CAREER
                        );
                        setAlert({
                          message:
                            "The Class(es) have been deleted from your Shopping Cart",
                          show: true,
                          type: "success",
                        });
                        window.scrollTo(0, 0);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                <div>
                  <Paragraph className="font-bold">Location</Paragraph>
                  <Paragraph>{item.Location}</Paragraph>
                </div>
                <div>
                  <Paragraph className="font-bold">Day and Time</Paragraph>
                  <Paragraph>{item.Dates}</Paragraph>
                </div>
                <div>
                  <Paragraph className="font-bold">Instructor</Paragraph>
                  <Paragraph>{item.Instructor}</Paragraph>
                </div>
                <div>
                  <Paragraph className="font-bold">Class Number</Paragraph>
                  <Paragraph>{item.ClassNbr}</Paragraph>
                </div>
                <div>
                  <Paragraph className="font-bold">Classroom</Paragraph>
                  <Paragraph>{item.Room}</Paragraph>
                </div>
                <div>
                  <Paragraph className="font-bold">Status</Paragraph>
                  {item.Status === "O" && (
                    <div className="flex items-center gap-1.5">
                      <div className="inline-block h-[15px] w-[15px] rounded-full bg-[#108A27]" />
                      <Paragraph>Seats Available</Paragraph>
                    </div>
                  )}
                  {item.Status === "C" && (
                    <div className="flex items-center gap-1.5">
                      <div className="inline-block h-[15px] w-[15px] rounded-full bg-[#8A1410]" />
                      <Paragraph>Closed</Paragraph>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {cartItems.length === 0 && term.$id !== "" && (
        <Paragraph>
          You don't have any {term.$name} classes in your cart.
        </Paragraph>
      )}
      <EditGrading
        career={idCard ? idCard.CAREER : ""}
        gradingModal={gradingModal}
        setGradingModal={setGradingModal}
        actionButton={(isValidOption, handleOnClick) => (
          <button
            className={clsx(
              "border-none px-5 py-[10px] font-bold",
              isValidOption
                ? "bg-du-yellow text-du-black"
                : "bg-du-dark-gray text-white"
            )}
            onClick={() => {
              handleOnClick();
            }}
          >
            Save
          </button>
        )}
      />
      <ConfirmGrading
        gradingModal={gradingModal}
        setGradingModal={setGradingModal}
        action={async (grading) => {
          if (currCartItem && term.STRM && term.ACAD_CAREER) {
            await handleSaveClassToCart(currCartItem, grading);
            await fetchShoppingCartDetails(term.STRM, term.ACAD_CAREER);
            setAlert({
              message: `The details are updated to the Class in your Cart ${currCartItem.ClassNbr}`,
              show: true,
              type: "success",
            });
            window.scrollTo(0, 0);
          }
        }}
      />
    </div>
  );
};

export default CartList;
