import clsx from "clsx";
import React, { Fragment } from "react";

interface HighlightedProps {
  text: string;
  highlight: string;
}

const Highlighted: React.FC<HighlightedProps> = ({ text, highlight }) => {
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));

  if (!highlight || !parts) return <Fragment>{text}</Fragment>;
  return (
    <span>
      {" "}
      {parts.map((part, i) => (
        <span
          key={i}
          className={clsx(
            part.toLowerCase() === highlight.toLowerCase() &&
              "rounded-sm bg-[#fbe59d]"
          )}
        >
          {part}
        </span>
      ))}{" "}
    </span>
  );
};

export default Highlighted;
